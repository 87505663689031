<template>

  <div class="card mb-5 mb-xxl-8" v-if="profile">

    <div class="card-body pt-9 pb-0">
      
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        
        <div class="me-7 mb-4">
          <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
            <img :src="'https://api.ytmdanismanlik.com/'+profile.profileImageDirectory+profile.profileImage" alt="image" />
            <div
              class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
            ></div>
          </div>
        </div>
        

        
        <div class="flex-grow-1">
          <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <span class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{{profile.name+ ' '+profile.surname}}</span>
                <span>({{genders[profile.gender]}}, {{Math.round(moment().diff(profile.birthday,'years',true))}} Yaş)</span>
              </div>
              
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                

                <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                  {{profile.city}}
                </a>

                <a
                  href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5  mb-2">
                  +{{profile.prefix}}{{profile.phone}}
                </a>
                <a
                  href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                  {{profile.email}}
                </a>
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

           
          </div>
          <!--end::Title-->

          
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <!--begin::Navs-->
      <div class="d-flex overflow-auto h-55px" v-if="tabs">
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">          
          <li class="nav-item" v-for="(tab, i) in tabs" :key="i">
            <router-link
				v-if="tab"
              :to="tab.route.replace('{user_id}',profile.userId)"
              class="nav-link text-active-primary me-6"
              active-class="active">
             {{tab.title}}
             
            </router-link>
          </li>
          
          
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <!--end::Navbar-->
  <router-view v-if="profile" :profile="profile"></router-view>
</template>

<script>
import { defineComponent } from "vue";

import api from '@/core/services/ApiService'

import moment from 'moment'
import labels from "@/core/config/labels";



export default defineComponent({
  name: "profile",
  components: {
    
  },
  data(){

    return {

      tabs:
        [
          {
            route:'/consultant/{user_id}/settings',
            title:'Ayarlar'
          },
          {
            route:'/consultant/{user_id}/calendar',
            title:'Takvim'
          },
          {
            route:'/consultant/{user_id}/files',
            title:'Dosyalar'
          },
          {
            route:'/consultant/{user_id}/pricing',
            title:'Ücretlendirme'
          },
          {
            route:'/consultant/{user_id}/special-pricings',
            title:'Özel Fiyatlar'
          },
          {
            route:'/consultant/{user_id}/profile_photo',
            title:'Profil Fotoğafı'
          },
          
          
        ],
      

      profile:null,
      genders:labels.genders
    }
  },
  
  methods:{
    moment(date){
      return moment(date);
    }
  },
  mounted(){
	  
    let user_id = this.$route.params.user_id;
    api.get('/User/GetUser/'+user_id).then((res)=>{
      this.profile = res.data;
	  console.log(this.profile);
    });

  }
});
</script>
